.errorClass{
    color: red;
}

.cursor-pointer{
    cursor: pointer;
}
.text-green{
    color: green;
}
.export{
    width: 100%;
      margin: 0px;
  }
  .fontH3{
      font-size: 5.00rem;
      color:#fff
  }
.creditDebit{
    font-size: 16px;
    font-weight: 600;
}
.fa-10x {
    font-size: 10em !important;
    color: black;
  }
  .scrollable-content {
    height:100%;
    max-height: 230px;
    width: 100%;
    overflow-y: scroll;
}

.tile-stats{
    color: white;
    position: relative;
    display: block;
    background: #303641;
    padding: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    -webkit-border-radius: 5px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 5px;
    -moz-background-clip: padding;
    border-radius: 5px;
    background-clip: padding-box;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}.tile-stats {
    height: 180px;
}.tile-stats.tile-red {
    background: #f56954;
}
.tile-stats.tile-blue {
    background: #3caee2;
}
.tile-stats.tile-yellow {
    background: #a9ee98;
}
.tile-stats.tile-green {
    background: #52e42d;
}
.tile-stats.tile-skyblue {
    background: #4cbbee;
}
.tile-stats.tile-peace {
    background: #cee2eb;
}
.tile-stats .icon {
    color: rgba(81, 29, 29, 0.16);
    position: absolute;
    right: 9px;
    bottom: 3px;
    z-index: 1;
    font-size: 30px;
}
.table_j12>tbody>tr>td {
    border-top: none!important;
    color: #fff;
}

.h3_dashboard{
    color: #fff;
    font-size: 20px;
    margin-bottom: 0;
}
.h4_dashboard{
    font-size: 18px;
    margin-bottom: 3px;
    color:#000000c7
}
.m-l-5{
    margin-left: 5px;
}
/* loading */

.preloader {
    background-color: rgba(255, 255, 255, 0.64);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    }
    
    .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 1
    }
    
    .spinner .double-bounce1,
    .spinner .double-bounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #000;
        opacity: .6;
        position: absolute;
        top: 0;
        left: 0;
        animation: sk-bounce 2s infinite ease-in-out;
    }
    
    .spinner .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s
    }
    
    @-webkit-keyframes sk-bounce {
    0%,
    100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0)
    }
    50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
    }
    }
    
    @keyframes sk-bounce {
    0%,
    100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0)
    }
    50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
    }
    }

/* loading */

